
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElLoading } from "element-plus";
import { ElNotification } from "element-plus";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
// import '@/assests/sass/style.scss'


// import KTTopbar from "@/layout/header/Topbar.vue";

import alasql from "alasql";
// import FilterAnalytic from "@/layout/header/partials/AnalyticFilter.vue";
import FilterAnalytic from "./AnalyticFilter.vue"

export default defineComponent({
  name: "Analytics",
  mixins: [Globals],
  components: {
    FilterAnalytic,
    // KTTopbar
  },

  data() {
    return {
      render: false,
      graph: true,
      filterOption: "Manufacturer",
      showcalls: true,
      showerrors: false,
      currentDate: "",
      currentMonth: "",
      monthYear: [] as any,
      myString: "",
      APIData1: [],
      selected: false,
      showGraph: false,
      APIData: [] as any,
      starting: "",
      final: "",
      presentYear: "",
      months: [] as any,
      startDate: "",
      endDate: "",
      manufacturerData: [] as any,
      categoriesData: [] as any,
      productData: [] as any,
      apisData: [] as any,
      finalData: [] as any,

      checkBoxStatus: [] as any,
      manufacturerArray: [] as any,
      categoryArray: [] as any,
      productArray: [] as any,
      apiArray: [] as any,

      manufacturerApiCalls: [] as any,
      categoryApiCalls: [] as any,
      productApiCalls: [] as any,
      manufacturerApiError: [] as any,
      categoryApiError: [] as any,
      productApiError: [] as any,

      shortcuts: [
        {
          text: "Last week",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      value2: "",
      value: "",
      year: "",
    };
  },

  methods: {
    swap(myString) {
      let words = myString.split("-");
      [words[0], words[words.length - 1]] = [words[words.length - 1], words[0]];
      return words.join("-");
    },

    dateSearch(date) {  
      this.startDate = this.convertDateToString(date[0])
      this.endDate = this.convertDateToString(date[1])
      this.currentDate = this.endDate.split("-")[0];
      this.currentMonth = this.endDate.split("-")[1];
      let current = Number(this.currentDate);
      let month = Number(this.currentMonth);

      month--;
      let allMonths = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
      this.monthYear = [];
      for (let i = 0; i < 12; i++) {
        this.monthYear.push(
          allMonths[month] + "-" + current.toString().slice(2, 4)
        );
        month--;
        if (month < 0) {
          // current = current - 1;
          month = 11;
        }
      }
      this.monthYear=this.monthYear.reverse()

      this.manufacturerData=[],
      this.categoriesData= [],
      this.productData=[],
      this.apisData= [],

      
      this.manufacturerArray=[],
       this.categoryArray=[],
       this.productArray=[],
       this.apiArray=[],

       this.manufacturerApiCalls=[],
       this.categoryApiCalls=[],
       this.productApiCalls=[],
       this.manufacturerApiError=[],
       this.categoryApiError=[],
       this.productApiError=[],
    this.getendPointData();
    this.selection();

      
    },

    getendPointData() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(
        "/consumer/apimonitoring/data?organizationId=" +
          this.globalData.organizationId +
          "&startDate=" +
          this.startDate +
          "&endDate=" +
          this.endDate
      )
        .then((data) => {
          loading.close();
          if (data.data && data.data.data) {
            this.displayData(data.data.data);
          }
          else {
            this.displayData([]);
          }
          
        })
        .catch((error) => {
          loading.close();
          ElNotification({
            title: "Warning",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "warning",
            position: "top-right",
          });
        });
    },

    displayData(data) {
      this.APIData = JSON.parse(JSON.stringify(data));
      let manufacturerData = alasql(
        "SELECT DISTINCT [Manufacturer Organization] FROM ? ",
        [this.APIData]
      );      
      for (let i = 0; i < manufacturerData.length; i++) {
        this.manufacturerData.push(
          manufacturerData[i]["Manufacturer Organization"]
        );
      }


      let categoryData = alasql("SELECT DISTINCT Category FROM ? ", [
        this.APIData,
      ]);
      for (let i = 0; i < categoryData.length; i++) {
        this.categoriesData.push(categoryData[i]["Category"]);
      }

      let productData = alasql("SELECT DISTINCT [Product Group] FROM ? ", [
        this.APIData,
      ]);
      for (let i = 0; i < productData.length; i++) {
        this.productData.push(productData[i]["Product Group"]);
      }

      let apiData = alasql("SELECT DISTINCT [API EndPoint] FROM ? ", [
        this.APIData,
      ]);
      for (let i = 0; i < apiData.length; i++) {
        this.apisData.push(apiData[i]["API EndPoint"]);
      }

      this.selection();

      this.display();
    },

    storeAPI(index, event) {
      if (event.target.checked) {
        for (let apis of this.apisData) {
          if (apis == index) this.apiArray.push(apis);
        }
      } else {
        for (let i = 0; i < this.apiArray.length; i++) {
          if (this.apiArray[i] == index) this.apiArray.splice(i, 1);
        }
      }
    },

    storeProduct(index, event) {
      if (event.target.checked) {
        for (let product of this.productData) {
          if (product == index) this.productArray.push(product);
        }
      } else {
        for (let i = 0; i < this.productArray.length; i++) {
          if (this.productArray[i] == index) this.productArray.splice(i, 1);
        }
      }
    },

    storeCategory(index, event) {
      if (event.target.checked) {
        for (let category of this.categoriesData) {
          if (category == index) this.categoryArray.push(category);
        }
      } else {
        for (let i = 0; i < this.categoryArray.length; i++) {
          if (this.categoryArray[i] == index) this.categoryArray.splice(i, 1);
        }
      }
    },

    storeManufacturer(index, event) {      
      if (event.target.checked) {
        for (let manufacture of this.manufacturerData) {
          if (manufacture == index) this.manufacturerArray.push(manufacture);
        }
      } else {
        for (let i = 0; i < this.manufacturerArray.length; i++) {
          if (this.manufacturerArray[i] == index)
            this.manufacturerArray.splice(i, 1);
        }
      }
    },

    selectAll() {
      this.checkBoxStatus = document.getElementsByName("chk");

      for (
        var checkBoxIndex = 0;
        checkBoxIndex < this.checkBoxStatus.length;
        checkBoxIndex++
      ) {
        if (this.checkBoxStatus[checkBoxIndex].type == "checkbox") {
          this.checkBoxStatus[checkBoxIndex].checked = true;
        }
      }
      for (let manufacture of this.manufacturerData) {
        this.manufacturerArray.push(manufacture);
      }

      for (let category of this.categoriesData) {
        this.categoryArray.push(category);
      }

      for (let product of this.productData) {
        this.productArray.push(product);
      }

      for (let apis of this.apisData) {
        this.apiArray.push(apis);
      }
    },

    unselectAll() {
      this.checkBoxStatus = document.getElementsByName("chk");
      for (
        var checkBoxIndex = 0;
        checkBoxIndex < this.checkBoxStatus.length;
        checkBoxIndex++
      ) {
        if (this.checkBoxStatus[checkBoxIndex].type == "checkbox") {
          this.checkBoxStatus[checkBoxIndex].checked = false;
        }
      }
      this.manufacturerArray = [];
      this.categoryArray = [];
      this.productArray = [];
      this.apiArray = [];
    },

    selection() {
      this.selected = !this.selected;

      if (this.selected) {
        this.selectAll();
      } else {
        this.unselectAll();
      }
    },
    convertDateToString(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    calculateDate() {
       let current = new Date().getFullYear();      
      let month = new Date().getMonth();
      let todayDate = new Date();
      this.endDate = todayDate
        .toISOString()
        .replace("T", " ")
        .split(".")[0]
        .split(" ")[0];
      this.currentDate = this.endDate;
      var d = new Date();
      d.setDate(1);
      for (let i = 0; i < 11; i++) {
        d.setMonth(d.getMonth() - 1);
      }
      this.startDate = d
        .toISOString()
        .replace("T", " ")
        .split(".")[0]
        .split(" ")[0];
      if (month == 0) {
        // current = current;
        this.presentYear = current.toString();
      }
      // if (month - 1 < 0) (month = 11), (this.presentYear = current.toString());
      // else {
      //   this.presentYear = current.toString();
      // }

      let allMonths = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
      for (let i = 0; i < 12; i++) {
        this.monthYear.push(
          allMonths[month] + "-" + current.toString().slice(2, 4)
        );
        month--;
        if (month < 0) {
          current = current - 1;
          month = 11;
        }
      }
      this.monthYear=this.monthYear.reverse()
    },
    ChangeT(test){
      this.manufacturerArray=test[0]
      this.categoryArray=test[1]
      this.productArray=test[2]
      this.apiArray=test[3]
      this.value2=test[4]
      if(this.value2){        
        this.dateSearch(this.value2)
      }
      this.display()
    },

    async createSeries() {
      // this.graph=false
      this.render = false;
      this.manufacturerApiCalls = [];
      this.manufacturerApiError = [];
      this.categoryApiCalls = [];
      this.categoryApiError = [];
      this.productApiCalls = [];
      this.productApiError = [];

      for (
        let manufacturerIndex = 0;
        manufacturerIndex < this.manufacturerArray.length;
        manufacturerIndex++
      ) {
        let body = {};
        body["name"] = this.manufacturerArray[manufacturerIndex];
        body["data"] = [];
        this.manufacturerApiCalls.push(body);
      }
      for (
        let manufacturerIndex = 0;
        manufacturerIndex < this.manufacturerArray.length;
        manufacturerIndex++
      ) {
        let body = {};
        body["name"] = this.manufacturerArray[manufacturerIndex];
        body["data"] = [];
        this.manufacturerApiError.push(body);
      }

      for (
        let categoryIndex = 0;
        categoryIndex < this.categoryArray.length;
        categoryIndex++
      ) {
        let body = {};
        body["name"] = this.categoryArray[categoryIndex];
        body["data"] = [];
        this.categoryApiCalls.push(body);
      }

      for (
        let categoryIndex = 0;
        categoryIndex < this.categoryArray.length;
        categoryIndex++
      ) {
        let body = {};
        body["name"] = this.categoryArray[categoryIndex];
        body["data"] = [];
        this.categoryApiError.push(body);
      }

      for (
        let productIndex = 0;
        productIndex < this.productArray.length;
        productIndex++
      ) {
        let body = {};
        body["name"] = this.productArray[productIndex];
        body["data"] = [];
        this.productApiCalls.push(body);
      }

      for (
        let productIndex = 0;
        productIndex < this.productArray.length;
        productIndex++
      ) {
        let body = {};
        body["name"] = this.productArray[productIndex];
        body["data"] = [];
        this.productApiError.push(body);
      }
    },

    async display() {
      this.createSeries();
      this.render = true;
      let manuData = [] as any;
      let cateData = [] as any;
      let prodData = [] as any;
      let apData = [] as any;

      for (let i = 0; i < this.manufacturerArray.length; i++) {
        let manuf = alasql(
          "SELECT * FROM ? WHERE [Manufacturer Organization]==?",
          [this.APIData, this.manufacturerArray[i]]
        );
        for (let j = 0; j < manuf.length; j++) {
          manuData.push(manuf[j]);
        }
      }
      for (let i = 0; i < this.categoryArray.length; i++) {
        let cat = alasql("SELECT * FROM ? WHERE Category==?", [
          manuData,
          this.categoryArray[i],
        ]);
        for (let j = 0; j < cat.length; j++) {
          cateData.push(cat[j]);
        }
      }
      for (let i = 0; i < this.productArray.length; i++) {
        let prod = alasql("SELECT * FROM ? WHERE [Product Group]==?", [
          cateData,
          this.productArray[i],
        ]);
        for (let j = 0; j < prod.length; j++) {
          prodData.push(prod[j]);
        }
      }
      for (let i = 0; i < this.apiArray.length; i++) {
        let apida = alasql("SELECT * FROM ? WHERE [API EndPoint]==?", [
          prodData,
          this.apiArray[i],
        ]);
        for (let j = 0; j < apida.length; j++) {
          apData.push(apida[j]);
        }
      }
      this.finalData = apData;
      if(this.finalData.length==0){
        ElNotification({
            title: "Warning",
            message:"No Data Found or Invalid Selction",
            type: "warning",
            position: "top-right",
          });
      }
      if (this.filterOption == "Manufacturer") {
        this.manufactOption.xaxis.categories = [];

        this.manufactOption.xaxis.categories = this.monthYear;
        for (
          let manufacturerIndex = 0;
          manufacturerIndex < this.manufacturerApiCalls.length;
          manufacturerIndex++
        ) {
          this.manufacturerApiCalls[manufacturerIndex]["data"] = [];
          this.manufacturerApiError[manufacturerIndex]["data"] = [];
        }

        for (
          let manufacturerIndex = 0;
          manufacturerIndex < this.manufacturerApiCalls.length;
          manufacturerIndex++
        ) {
          let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          let error = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

          let manufacturerData = alasql(
            "SELECT * FROM ? WHERE [Manufacturer Organization]==?",
            [
              this.finalData,
              this.manufacturerApiCalls[manufacturerIndex]["name"],
            ]
          );

          for (let i = 0; i < manufacturerData.length; i++) {
            let mY = "";
            mY =
              manufacturerData[i]["Month"] +
              "-" +
              manufacturerData[i]["Year"].toString().slice(2, 4);
            if (this.monthYear.indexOf(mY) != -1) {
              for (let j = 0; j < this.monthYear.length; j++) {
                if (this.monthYear[j] == mY) {
                  data[j] = data[j] + manufacturerData[i]["Calls"];
                  error[j] = error[j] + manufacturerData[i]["Errors"];
                  break;
                }
              }
            }
          }
          this.manufacturerApiCalls[manufacturerIndex]["data"] = data;
          this.manufacturerApiError[manufacturerIndex]["data"] = error;
        }
      }

      if (this.filterOption == "Category") {
        // this.cateOptions.xaxis.categories=[]
        this.manufactOption.xaxis.categories = [];
        // this.cateOptions.xaxis.categories = this.monthYear;
        this.manufactOption.xaxis.categories = this.monthYear;

        for (
          let categoryIndex = 0;
          categoryIndex < this.categoryApiCalls.length;
          categoryIndex++
        ) {
          this.categoryApiCalls[categoryIndex]["data"] = [];
          this.categoryApiError[categoryIndex]["data"] = [];
        }
      }

      for (
        let categoryIndex = 0;
        categoryIndex < this.categoryApiCalls.length;
        categoryIndex++
      ) {
        let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let error = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let categoryData = alasql("SELECT * FROM ? WHERE Category==?", [
          this.finalData,
          this.categoryApiCalls[categoryIndex]["name"],
        ]);
        for (let i = 0; i < categoryData.length; i++) {
          let mY = "";
          mY =
            categoryData[i]["Month"] +
            "-" +
            categoryData[i]["Year"].toString().slice(2, 4);
          if (this.monthYear.indexOf(mY) != -1) {
            for (let j = 0; j < this.monthYear.length; j++) {
              if (this.monthYear[j] == mY) {
                data[j] = data[j] + categoryData[i]["Calls"];
                error[j] = error[j] + categoryData[i]["Errors"];
                break;
              }
            }
          }
        }
        this.categoryApiCalls[categoryIndex]["data"] = data;
        this.categoryApiError[categoryIndex]["data"] = error;
      }

      if (this.filterOption == "ProductGroup") {
        // this.prodOptions.xaxis.categories=[]
        // this.prodOptions.xaxis.categories = this.monthYear;
        this.manufactOption.xaxis.categories = [];
        this.manufactOption.xaxis.categories = this.monthYear;

        for (
          let productIndex = 0;
          productIndex < this.productApiCalls.length;
          productIndex++
        ) {
          this.productApiCalls[productIndex]["data"] = [];
          this.productApiError[productIndex]["data"] = [];
        }

        for (
          let productIndex = 0;
          productIndex < this.productApiCalls.length;
          productIndex++
        ) {
          let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          let error = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

          let productData = alasql("SELECT * FROM ? WHERE [Product Group]==?", [
            this.finalData,
            this.productApiCalls[productIndex]["name"],
          ]);
          for (let i = 0; i < productData.length; i++) {
            let mY = "";
            mY =
              productData[i]["Month"] +
              "-" +
              productData[i]["Year"].toString().slice(2, 4);
            if (this.monthYear.indexOf(mY) != -1) {
              for (let j = 0; j < this.monthYear.length; j++) {
                if (this.monthYear[j] == mY) {
                  data[j] = data[j] + productData[i]["Calls"];
                  error[j] = error[j] + productData[i]["Errors"];

                  break;
                }
              }
            }
          }
          this.productApiCalls[productIndex]["data"] = data;
          this.productApiError[productIndex]["data"] = error;
        }
      }
    },

    showCard(card) {
      if (card == "Error") {
        this.showcalls = false;
        this.showerrors = true;
      } else {
        this.showcalls = true;
        this.showerrors = false;
      }
    },
  },

  mounted() {
    MenuComponent.reinitialization();
    this.calculateDate();
    this.getendPointData();
  },

  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Monitoring", ["API"]);
    });
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const blueColor = getCSSVariableValue("--bs-blue");
    const yellowColor = getCSSVariableValue("--bs-warning");
    const greenColor = getCSSVariableValue("--bs-success");
    const redColor = getCSSVariableValue("--bs-red");
    const violetColor = getCSSVariableValue("--bs-violet");

    const cateOptions = {
      chart: {
        type: "bar",
        height: "100%",
        stacked: true,
        toolbar: {
          show: false,
        },
         grid: {
        show: false,      // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false  //or just here to disable only x axis grids
          }
        },  
        yaxis: {
          lines: { 
            show: false  //or just here to disable only y axis
          }
        },   
      },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          columnWidth: ["100%"],
        },
      },
      xaxis: {
        categories: [] as any,
      },
      fill: {
        opacity: 1,
      },
      colors: [yellowColor, greenColor, blueColor, redColor],
    };

    const prodOptions = {
      chart: {
        type: "bar",
        height: "100%",
        stacked: true,
        toolbar: {
          show: false,
        },
         grid: {
        show: false,      // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false  //or just here to disable only x axis grids
          }
        },  
        yaxis: {
          lines: { 
            show: false  //or just here to disable only y axis
          }
        },   
      },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          columnWidth: ["100%"],
        },
      },
      xaxis: {
        categories: [] as any,
      },
      fill: {
        opacity: 1,
      },
      colors: [yellowColor, greenColor, blueColor, redColor, violetColor],
    };

    const manufactOption = {
      chart: {
        type: "bar",
        height: "100%",
        stacked: true,
        toolbar: {
          show: false,
        },
        
        zoom: {
          enabled: true,
        },
      },
       grid: {
        show: false,      // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false  //or just here to disable only x axis grids
          }
        },  
        yaxis: {
          lines: { 
            show: false  //or just here to disable only y axis
          }
        },   
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 10,
          columnWidth: "100%",
        },
      },
      xaxis: {
        categories: [] as any,
      },
      fill: {
        opacity: 1,
      },
      colors: [yellowColor, greenColor, blueColor, redColor],
    };
    // const series = [
    //   {
    //     name: "HDFC",
    //     data: [44, 55, 57, 56, 61, 58],
    //   },
    //   {
    //     name: "EDL",
    //     data: [76, 85, 101, 98, 87, 105],
    //   },
    //   {
    //     name: "ABKS",
    //     data: [56, 45, 85, 68, 73, 99],
    //   },
    // ];

    return {
      cateOptions,
      prodOptions,
      manufactOption,
    };
  },
});
