
import { defineComponent, ref,computed } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Globals from "@/views/common/Globals.vue";

export default defineComponent({
  name: "FilterAnalytic",
  props:{
    manu:{type:Array},
    cate:{type:Array},
    prod:{type:Array},
    ap:{type:Array},
  },
  mixins: [Globals],
  components: {},
    data() {
    return {
        manufacturerArray1:[] as any,
        productArray1:[] as any,
        categoryArray1:[] as any,
        apiArray1:[] as any,

          manufacturerData:this.manu,
           categoriesData:this.cate,
            productData:this.prod,
             apisData:this.ap,
             shortcuts: [
        {
          text: "Last week",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      value3: "",
        

    }},

    methods:{
      storeAPI(index, event) {
      if (event.target.checked) {
        for (let apis of this.apisData) {
          if (apis == index) this.apiArray1.push(apis);
        }
      } else {
        for (let i = 0; i < this.apiArray1.length; i++) {
          if (this.apiArray1[i] == index) this.apiArray1.splice(i, 1);
        }
      }
    },

    storeProduct(index, event) {
      if (event.target.checked) {
        for (let product of this.productData) {
          if (product == index) this.productArray1.push(product);
        }
      } else {
        for (let i = 0; i < this.productArray1.length; i++) {
          if (this.productArray1[i] == index) this.productArray1.splice(i, 1);
        }
      }
    },

    storeCategory(index, event) {
      if (event.target.checked) {
        for (let category of this.categoriesData) {
          if (category == index) this.categoryArray1.push(category);
        }
      } else {
        for (let i = 0; i < this.categoryArray1.length; i++) {
          if (this.categoryArray1[i] == index) this.categoryArray1.splice(i, 1);
        }
      }
    },
      storeManufacturer(index, event) {  
      if (event.target.checked) {
        for (let manufacture of this.manufacturerData) {
          if (manufacture == index) this.manufacturerArray1.push(manufacture);
        }
      } else {
        for (let i = 0; i < this.manufacturerArray1.length; i++) {
          if (this.manufacturerArray1[i] == index)
            this.manufacturerArray1.splice(i, 1);
        }
      }
    },
    display1(){      
        this.$emit("changeTitle", [this.manufacturerArray1,this.categoryArray1,this.productArray1,this.apiArray1,this.value3]);
           hideModal(this.addCustomerModalRef);
   },
    },
    setup() {
    const addCustomerModalRef = ref<null | HTMLElement>(null);
    // const loading = ref<boolean>(false);

    return {
   
    
      addCustomerModalRef,
    };
  },
});
